import React, { FC, useState } from 'react';
import { MainFrame } from '../components/main-frame';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Texts } from '../../../shared/types';
import { SIGN_UP_ROUTES } from './route.definitions';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHttpMutation } from '../../../api';
import { EMAIL_PATTERN } from '../../../shared/consts';
import { BhabiLink, PasswordInput, useAlert } from '../../../shared/components';
import { useNavigate } from 'react-router-dom';

export interface SignUpPageProps {
  texts?: Texts<'signUpTitle' | 'button'>;
}

export const SignUpPage: FC<SignUpPageProps> = ({
  texts = {
    signUpTitle: SIGN_UP_ROUTES.TITLE,
    button: 'Sign Up',
  },
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
  });

  const { mutate: signUp } = useSignUp();

  const { addAlert } = useAlert();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Omit<SignUpInput, 'firebaseUID'>> = data => {
    signUp(
      { ...data, email: data.email.trim().toLowerCase() },
      {
        onError: err => {
          addAlert({
            type: 'error',
            message: err.response?.data?.message || 'Failed to Sign Up',
          });
        },
        onSuccess: () => {
          addAlert({
            type: 'success',
            message: 'Signed up successfully, please login.',
          });

          navigate('/');
        },
      }
    );
  };

  const [acceptedToc, setAcceptedToc] = useState(false);

  return (
    <MainFrame
      stackProps={{ onSubmit: handleSubmit(onSubmit) }}
      linkPath="/"
      texts={{
        linkMessage: 'Already have an account? Sign in here',
        forgotPassword: '',
      }}
    >
      <Typography fontWeight={530} fontSize={20}>
        {texts.signUpTitle}
      </Typography>
      <TextField
        {...register('name', {
          required: `Name is required.`,
        })}
        placeholder="Name"
        error={!!errors.name}
        helperText={<>{errors.name?.message}</>}
      />

      <TextField
        {...register('email', {
          required: `Email is required.`,
          pattern: EMAIL_PATTERN,
        })}
        placeholder="Email"
        error={!!errors.email}
        helperText={<>{errors.email?.message}</>}
      />
      <PasswordInput<SignUpInput>
        controllerProps={{
          control,
          name: 'password',
          defaultValue: '',
          rules: {
            required: `Password is required.`,
            validate: value =>
              (value && value.length >= 8) || `Password is too short.`,
          },
        }}
        textFieldProps={{
          placeholder: 'Password',
          error: !!errors.password,
          helperText: <>{errors.password?.message}</>,
        }}
      />
      <FormControlLabel
        sx={{ width: '93%' }}
        control={<Checkbox onClick={() => setAcceptedToc(!acceptedToc)} />}
        label={<AcceptTermsOfServiceMsg />}
      />
      <Button type="submit" variant="contained" disabled={!acceptedToc}>
        {texts.button}
      </Button>
    </MainFrame>
  );
};

const AcceptTermsOfServiceMsg: FC = () => (
  <Typography variant="body2" color="textSecondary">
    {`By signing up for a Bhabi card game account, you agree to the `}
    <BhabiLink to="/toc">Terms of Service and Privacy Policy</BhabiLink>
  </Typography>
);

export type SignUpInput = {
  name: string;
  email: string;
  password: string;
  firebaseUID?: string;
};

export const useSignUp = () =>
  useHttpMutation<{ message: string }, SignUpInput>('/sign-up', 'POST');
