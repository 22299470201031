export const API_URLS = {
  JOIN_ROOM: '/join-room',
  LEAVE_ROOM: '/leave-room',
  REJECT_INVITE: '/reject-invite',
  CREATE_ROOM: '/create-room',
  INVITE: '/invite',
  SEARCH_USERS: '/search-users',
  RESEND_VERIFICATION_EMAIL: '/resend-email',
  VERIFY_EMAIL: '/verify-email',
  UPDATE_PROFILE_NAME: '/update-profile-name',
  IN_PLAY: '/in-play',
  BAZZI: '/bazzi',
  THOKA: '/thoka',
  NOT_CONCLUDED_ROOMS: '/get-not-concluded-rooms',
  RE_JOIN_ROOM: '/re-join-room',
  GET_USER_PUBLIC_PROFILE: '/user-public-profile',
  RATE_USER: '/rate-user',
  INVITE_BOTS: '/bot/invite-bots',
  BOT_PLAY: '/bot/bot-play',
  GET_USER_BY_ID: '/user/:id',
  ACCEPT_CARDS_REQUEST: '/accept-cards-request',
  REQUEST_CARDS: '/request-cards',
  DELETE_ACCOUNT: '/delete-account',
  GET_USERS_BY_FIREBASE_UID: '/users-by-firebaseUID',
  RE_MATCH: '/re-match',
};

export const ISO_PADDING = 'env(safe-area-inset-top)';
