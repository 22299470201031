import {
  AdLoadInfo,
  AdMob,
  AdOptions,
  AdmobConsentStatus,
  BannerAdOptions,
  BannerAdPluginEvents,
  BannerAdPosition,
  BannerAdSize,
  InterstitialAdPluginEvents,
} from '@capacitor-community/admob';
import { LoggerService } from '../logger';
import { Capacitor } from '@capacitor/core';
import { BhabiStorage } from './storage';

export class BhabiAdMob {
  private readonly adVisibleKey = 'bannerAdVisible';
  bannerVisible = false;
  constructor(private readonly disable: boolean = false) {
    if (Capacitor.getPlatform() === 'web' || this.disable) {
      return;
    }
    this.initialize();
  }

  async isBannerVisible(): Promise<boolean> {
    const isVisible = await BhabiStorage.getItem(
      v => v,
      this.adVisibleKey
    ).catch(() => 'false');

    return isVisible === 'true' ? true : false;
  }

  async initialize(): Promise<void> {
    await AdMob.initialize();
    const [trackingInfo, consentInfo] = await Promise.all([
      AdMob.trackingAuthorizationStatus(),
      AdMob.requestConsentInfo(),
    ]);
    if (trackingInfo.status === 'notDetermined') {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/
      await AdMob.requestTrackingAuthorization();
    }
    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (
      authorizationStatus.status === 'authorized' &&
      consentInfo.isConsentFormAvailable &&
      consentInfo.status === AdmobConsentStatus.REQUIRED
    ) {
      await AdMob.showConsentForm();
    }
  }

  private getBannerAdID(): string {
    return Capacitor.getPlatform() === 'android'
      ? (process.env.REACT_APP_BANNER_AD_UNIT_ID_ANDROID as string)
      : (process.env.REACT_APP_BANNER_AD_UNIT_ID_IOS as string);
  }

  async banner(opt?: Partial<BannerAdOptions>): Promise<void> {
    if (
      Capacitor.getPlatform() === 'web' ||
      this.disable ||
      this.bannerVisible
    ) {
      return;
    }
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
      LoggerService.info('BannerAdPluginEvents.Loaded');
    });
    // AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
    //   // Subscribe Change Banner Size
    // });
    const options: BannerAdOptions = {
      adId: this.getBannerAdID(),
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.TOP_CENTER,
      ...opt,
      // isTesting: true,
      // npa: true
    };
    try {
      await AdMob.showBanner(options);
      BhabiStorage.setItem(this.adVisibleKey, 'true', isErr => {
        if (!isErr) {
          this.bannerVisible = true;
        }
      });
    } catch (e) {
      LoggerService.error(e);
    }
  }

  async interstitial(): Promise<void> {
    if (Capacitor.getPlatform() === 'web' || this.disable) {
      return;
    }
    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared interstitial
      LoggerService.info('InterstitialAdPluginEvents.Loaded: ', info);
    });
    const options: AdOptions = {
      adId:
        Capacitor.getPlatform() === 'android'
          ? (process.env.REACT_APP_FULL_PAGE_AD_UNIT_ID_ANDROID as string)
          : (process.env.REACT_APP_FULL_PAGE_AD_UNIT_ID as string),
      // isTesting: true
      // npa: true
    };
    try {
      await AdMob.prepareInterstitial(options);
      await AdMob.showInterstitial();
    } catch (e) {
      LoggerService.error('InterstitialAdPluginEvents.Loaded: ', e);
    }
  }

  async removeBanner(): Promise<void> {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    try {
      await AdMob.hideBanner();
      BhabiStorage.setItem(this.adVisibleKey, 'false', isErr => {
        if (!isErr) {
          this.bannerVisible = false;
        }
      });
    } catch (e) {
      LoggerService.error(e);
    }
  }
}

export const adMob = new BhabiAdMob();
