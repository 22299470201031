import { Box, LinearProgress, alpha, styled } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import GroupIcon from '@mui/icons-material/Group';
import React, { FC, useEffect } from 'react';
import { useQuickPlay } from './hooks';
import { useNavigate } from 'react-router-dom';
import { CREATE_ROOM_ROUTE } from '../create-room';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux';
import { IN_PLAY_ROUTE } from '../in-play';
import { usePushNotifications } from '../../../shared/lib';
import { useLandscapeMode } from '@/shared/hooks';

export const HomePage: FC = () => {
  const { quickPlay, isError, isLoading } = useQuickPlay();
  const nav = useNavigate();
  const isInRoom = useSelector((state: Store) => state.userReducer.isInRoom);

  useEffect(() => {
    if (isLoading && !isError) return;

    if (isInRoom) {
      nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
    }
  }, [isError, isInRoom, isLoading, nav]);

  usePushNotifications();

  const isLandscapeMode = useLandscapeMode();

  return (
    <>
      {!isError && isLoading && (
        <LinearProgress className="mt-[10px]" color="secondary" />
      )}
      <HomePageWrapper className="container mx-auto">
        <InnerWrapper
          onClick={quickPlay}
          isDisabled={!isError && isLoading}
          isLandscapeMode={isLandscapeMode}
        >
          <ElectricBoltIcon
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '3rem',
            }}
          />
          Quick Play
        </InnerWrapper>
        <InnerWrapper
          onClick={() => {
            nav(CREATE_ROOM_ROUTE.ABSOLUTE_PATH);
          }}
          isLandscapeMode={isLandscapeMode}
        >
          <GroupIcon
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '3rem',
            }}
          />
          Play With Friends
        </InnerWrapper>
      </HomePageWrapper>
    </>
  );
};

export const HomePageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: theme.spacing(2),
  gridTemplateRows: 'auto',
  paddingTop: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

const InnerWrapper = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' && prop !== 'isLandscapeMode',
})<{ isDisabled?: boolean; isLandscapeMode: boolean }>(
  ({ theme, isDisabled, isLandscapeMode }) => ({
    margin: '0 auto',
    maxWidth: 400,
    minWidth: 300,
    cursor: 'pointer',
    fontSize: '2rem',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    ...(isDisabled && {
      color: theme.palette.text.disabled,
      cursor: 'not-allowed',
      pointerEvents: 'none',
    }),
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: theme.shadows[5],
    borderRadius: '18px',
    height: isLandscapeMode ? '40vh' : '250px',
    [theme.breakpoints.up('lg')]: {
      height: '300px',
    },
  })
);
