import { useEffect, useState } from 'react';

export const useLandscapeMode = () => {
  const [isLandscape, setIsLandscape] = useState(
    window.innerHeight < window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < window.innerWidth) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isLandscape;
};
