import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../redux';
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ThemeSwitch } from '../../../shared/components/theme-switch';
import { useHttpMutation } from '../../../api';
import { API_URLS } from '../consts';
import {
  User,
  updateUserState,
  userInitialState,
} from '../../../redux/reducers';
import { LoggerService } from '../../../shared/logger';
import { UserAvatar, useAlert, usePopUpBox } from '../../../shared/components';
import { CustomTabPanel, tabProps, useTabChange } from '../components';
import { ProfilePageStyled, PublicProfilePage } from './public-profile';
import { BhabiStorage } from '../../../shared/lib';
import { SIGN_IN_ROUTES } from '../../unauthenticated';
import { useNavigate } from 'react-router-dom';
import { UploadProfilePicture } from './upload-profile-picture';
import { ENV } from '@/shared/env';

export declare type ProfileInput = {
  name: string;
};

export const Profile: FC = () => {
  const { email, name, photoURL } = useSelector(
    (state: Store) => state.userReducer
  );
  const { mutate: changeName } = useHttpMutation<
    User & { isInRoom: boolean },
    ProfileInput
  >(API_URLS.UPDATE_PROFILE_NAME, 'POST');

  const { mutate: deleteAccount } = useHttpMutation(
    API_URLS.DELETE_ACCOUNT,
    'DELETE'
  );

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileInput>({
    mode: 'onSubmit',
    defaultValues: {
      name,
    },
  });

  const { addAlert } = useAlert();
  const nav = useNavigate();

  const onDeleteAccount = () => {
    deleteAccount(undefined, {
      onSuccess: async () => {
        addAlert({
          message: 'Account deleted successfully',
          type: 'success',
        });
        await BhabiStorage.removeItem('token');
        await BhabiStorage.removeItem('signedUpFor');
        dispatch(updateUserState({ ...userInitialState, isIdle: false }));
        nav(SIGN_IN_ROUTES.ABSOLUTE_PATH);
      },
      onError: err => {
        LoggerService.error(err);
        addAlert({
          message: 'Error deleting account',
          type: 'error',
        });
      },
    });
  };

  const onSubmit: SubmitHandler<ProfileInput> = ({ name }) => {
    changeName(
      { name },
      {
        onSuccess: res => {
          dispatch(updateUserState(res));

          addAlert({
            message: 'Profile updated successfully',
            type: 'success',
          });
        },
        onError: err => {
          LoggerService.error(err);
          addAlert({
            message: 'Error updating profile',
            type: 'error',
          });
        },
      }
    );
  };

  const { addPopUpBox } = usePopUpBox();

  const askToConfirm = () => {
    addPopUpBox(
      {
        message:
          'This action is irreversible and will delete all your data including all rooms and invites.',
        callback: status => {
          if (status) {
            onDeleteAccount();
          }
        },
      },
      {
        texts: {
          title: 'Delete Account?',
          yes: 'Delete',
          no: 'Cancel',
        },
      }
    );
  };

  return (
    <ProfilePageStyled className="container mx-auto">
      <Box
        elevation={2}
        component={Paper}
        maxWidth={{
          lg: '50%',
          md: '75%',
          sm: '100%',
        }}
        margin="auto"
      >
        <Box
          height={60}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={1}
        >
          <UserAvatar
            {...{
              photoURL,
              name,
              fetchCurrentUser: true,
            }}
          />
          {!ENV.REACT_APP_HIDE_THEME_CHANGE && <ThemeSwitch />}
        </Box>
        <Divider />
        <Stack component="form" py={2} onSubmit={handleSubmit(onSubmit)}>
          <Stack p={1} gap={2} pb={2}>
            <TextField
              {...register('name', {
                required: 'Name is required',
              })}
              placeholder="Name"
              variant="outlined"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <Typography
              {...{
                fontSize: '16px',
                fontWeight: 400,
                variant: 'body2',
                color: 'text.secondary',
              }}
            >
              {email}
            </Typography>
          </Stack>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            p={1}
          >
            <Button variant="contained" type="submit" size="small">
              Save
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box
        maxWidth={{
          lg: '50%',
          md: '75%',
          sm: '100%',
        }}
        margin="auto"
        mt={0.5}
      >
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={askToConfirm}
        >
          Delete Account
        </Button>
      </Box>
    </ProfilePageStyled>
  );
};

const TABS_NAMES = ['Profile', 'Settings', 'Picture'];
export const ProfilePage: FC = () => {
  const { handleTabChange, value } = useTabChange(TABS_NAMES);
  const { id } = useSelector((state: Store) => state.userReducer);

  return (
    <>
      <Tabs
        onChange={handleTabChange}
        aria-label="basic tabs"
        value={value}
        variant="fullWidth"
      >
        <Tab label="Profile" {...tabProps(0)} />
        <Tab label="Settings" {...tabProps(1)} />
        <Tab label="Picture" {...tabProps(2)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <PublicProfilePage id={id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Profile />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UploadProfilePicture />
      </CustomTabPanel>
    </>
  );
};
